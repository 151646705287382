import _ from "lodash";
import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

// Lcomotive Scroll Init

const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    multiplier: 9
});

// Local Time

function updateTime() {
    const now = new Date();
    const timeString = now.toLocaleTimeString();
    document.getElementById('time').textContent = timeString;
  }
  
  updateTime();
  setInterval(updateTime, 1000); 

// Locomotive Scroll to Work

const recentProjects = document.getElementById('recent-projects');
const workTrigger = document.getElementById('workLink');

const locomotiveScroll = new LocomotiveScroll();

function scrollTo(params) {
    const { target, options } = params;
    locomotiveScroll.scrollTo(target, options);
}

workTrigger.addEventListener('click', (event) => {
    scrollTo({ target: recentProjects, options: {
        offset: -30,
        duration: 2
    }});
});

// Landing Intro

var landingTimeline = gsap.timeline ({});

gsap.set(".logo-eclipse", { y: 75, opacity: 0 });
gsap.set(".preLoadCover h3 span", { visibility: 'visible' });
gsap.set(".preLoadCover", { y: 0, display: "block" });

landingTimeline.from('.preLoadCover h3 span', {
    y: 75,
    duration: 1,
    ease: "circ.out",
    stagger: .2
});
landingTimeline.to('.preLoader', {
    duration: 1.3,
    ease: "circ.out",
    opacity: 0
});
landingTimeline.to('.preLoadCover h3 span', {
    y: -75,
    duration: 1,
    ease: "circ.out"
}, "-=1");
landingTimeline.to('.preLoadCover', {
    y: -1200,
    ease: "power4.in",
    duration: 1
}, "-=1.3");
landingTimeline.from('.header-main span', {
    y: 75,
    opacity: 0,
    ease: "circ.out",
    stagger: .25
});
landingTimeline.to('.logo-eclipse', {
    y: 0,
    opacity: 1,
    ease: "circ.out",
    duration: .2
}, "-=0.5");
landingTimeline.from('.header__left div p', {
    y: 75,
    opacity: 0,
    ease: "circ.out",
    stagger: .05
});
landingTimeline.from('.social-nav', {
    y: 75,
    opacity: 0,
    ease: "circ.out",
    stagger: .05
}, "-=0.2");
landingTimeline.from('.part .part__section', {
    y: 75,
    opacity: 0,
    ease: "circ.out"
});
landingTimeline.timeScale(1.5);

// First Section

var firstSectionTimeline = gsap.timeline ({
    scrollTrigger: {
        trigger: '#recent-projects',
        start: 'top 800px'
    }
});

gsap.set("#reelisterProjectLink", { opacity: 0 });

firstSectionTimeline.from('#recent-projects',{
    transformOrigin: 'left', 
    width: '0%' ,
    ease: "circ.out"
});
firstSectionTimeline.from('#recent-projects-span',{
    y:75,
    ease: "circ.out"
});
firstSectionTimeline.from('#reelisterCover',{
    y: 75, 
    opacity: 0, 
    ease: "circ.out"
});
firstSectionTimeline.from('#reelisterSection div',{
    y: 75,
    opacity: 0,
    stagger: .05, 
    ease: "circ.out"
});
firstSectionTimeline.to('#reelisterProjectLink',{
    opacity: 1,
    ease: "circ.out"
});

// Second Section

var secondSectionTimeline = gsap.timeline ({
    scrollTrigger: {
        trigger: '#archive',
        start: 'top 800px'
    }
});

gsap.set("#metroProjectLink", { opacity: 0 });

secondSectionTimeline.from('#archive',{
    transformOrigin: 'left', 
    width: '0%' ,
    ease: "circ.out"
});
secondSectionTimeline.from('#archive-span',{
    y:75,
    ease: "circ.out"
});
secondSectionTimeline.from('#metroCover',{
    y: 75, 
    opacity: 0, 
    ease: "circ.out"
});
secondSectionTimeline.from('#metroSection div',{
    y: 75,
    opacity: 0,
    stagger: .05, 
    ease: "circ.out"
});
secondSectionTimeline.to('#metroProjectLink',{
    opacity: 1,
    ease: "circ.out"
});

// Third Section

var thirdSectionTimeline = gsap.timeline ({
    scrollTrigger: {
        trigger: '#rbgTrigger',
        start: 'top 800px'
    }
});

gsap.set("#rbgProjectLink", { opacity: 0 });

thirdSectionTimeline.from('#rbgCover',{
    y: 75, 
    opacity: 0, 
    ease: "circ.out"
});
thirdSectionTimeline.from('#rbgSection div',{
    y: 75,
    opacity: 0,
    stagger: .05, 
    ease: "circ.out"
});
thirdSectionTimeline.to('#rbgProjectLink',{
    opacity: 1,
    ease: "circ.out"
});

// Fourth Section

var fourthSectionTimeline = gsap.timeline ({
    scrollTrigger: {
        trigger: '#clientsTrigger',
        start: 'top 800px'
    }
});

fourthSectionTimeline.from('#clientsSection div',{
    y: 75,
    opacity: 0,
    stagger: .05, 
    ease: "circ.out"
});

/*
gsap.utils.toArray(".part").forEach(section => {
    gsap.from(section.querySelectorAll(".project-img__wrapper, .project-body"), {
        scrollTrigger: section,
        y: 75,
        autoAlpha: 0,
        duration: 1,
        stagger: 0.25
    });
});

*/